
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { ModuleScheduleSection as ModuleSection } from '@common/clients/api';
import { PromiseHolder } from '@common/utils/PromiseHolder';
import { fetchActiveTagNewsListModules } from '@web/handlers/fetchActiveTagNewsListModules';
import { fetchNews } from '@web/handlers/fetchNews';
import { fetchResults } from '@web/handlers/fetchResults';
import { fetchSelection } from '@web/handlers/fetchSelection';
import { fetchTag } from '@web/handlers/fetchTag';
import { fetchTeam } from '@web/handlers/fetchTeam';
import { fetchTeamFixtures } from '@web/handlers/fetchTeamFixtures';
import { getNextWebServerSidePropsWrapper, GetProps, GetPropsReturn, NextWebServerSideProps, Route, } from '@web/routing';
import { ClubDetail, Props, State } from '@web/templates/football/ClubDetail';
const prepareProps: GetProps<Props> = async (contextData, serverContext): GetPropsReturn<Props> => {
    const query = serverContext.query || {};
    const tagSlug = String(query.tag) || '';
    const state: PromiseHolder<State> = {
        currentPage: 1,
        newsItems: [],
        competitionSlug: tagSlug,
        optaID: '',
        isLoading: false,
        fixtures: [],
        results: [],
        selection: {
            coaches: [],
            defenders: [],
            attackers: [],
            goalkeepers: [],
            midfielders: []
        },
        promoItems: []
    };
    const props: PromiseHolder<Props> = {
        contextData,
        state: state,
        showOdds: false
    };
    const tag = await fetchTag(tagSlug, contextData, false);
    if (!tag) {
        return {
            notFound: true
        };
    }
    const newsPromise = fetchNews({
        contextData,
        isClientSide: false,
        tagID: tag.tagID
    });
    state.tag = tag;
    tag.optaID = tag.optaID || '';
    // betting
    state.promoItems = fetchActiveTagNewsListModules(contextData, tag.tagID, false);
    const modulesBySection = await contextData.modulesBySection;
    if (tag.optaID && tag.isCurated) {
        const oddsMatchesModule = modulesBySection?.[ModuleSection.ODDS_MATCHES]?.[0];
        state.team = fetchTeam(contextData, tag.optaID, false);
        state.fixtures = fetchTeamFixtures(contextData, tag.optaID, oddsMatchesModule, false);
        state.results = fetchResults(contextData, tag.optaID);
        state.selection = fetchSelection(contextData, tag.optaID);
    }
    const news = await newsPromise;
    if (news?.data) {
        state.newsItems = news.data;
        state.pagination = news.pagination;
    }
    contextData.alternateLinks = tag.alternateLinks;
    const oddsMatchesModule = modulesBySection?.[ModuleSection.ODDS_MATCHES]?.[0];
    props.showOdds = serverContext?.query?.betting === 'true' || oddsMatchesModule !== undefined;
    return props;
};
const getServerSideProps: NextWebServerSideProps<Props> = getNextWebServerSidePropsWrapper(prepareProps, Route.Club);
export const Home = ClubDetail;
export default Home;

    async function __Next_Translate__getServerSideProps__193b9fe79e3__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/clubs/[tag]',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__193b9fe79e3__ as getServerSideProps }
  